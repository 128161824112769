<template>
  <div>
    <div
      v-if="isFinalyNode"
    >
      <span style="margin-right: 10px">
        {{ documentNode.name }}
      </span>
      <a-button size="small" disabled type="primary" v-if="isCanSend"> 立即发送 </a-button>
      <a-popconfirm v-else placement="topRight" title="确定发送？" @confirm="() => carbonCopy()">
        <a-button size="small" type="primary"> 立即发送 </a-button>
      </a-popconfirm>
    </div>
    <div v-else>
      {{ documentNode.name }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 节点信息
    documentNode: {
      type: Object,
      default() {
        return {}
      }
    },
    // 操作类型
    todoType: {
      type: String,
      default: ''
    },
    // 当前节点的序号
    documentNodeIndex: {
      type: Number,
      default: 0
    },
    // 当前流程节点序号
    stepIndex: {
      type: Number,
      default: 0
    },
    // 公文接收状态
    recipientStatus: {
      type: Number,
      default: 0
    },
    isCanSend: {
      type: Boolean
    }
  },
  computed: {
    isFinalyNode() {
      return this.documentNode.sort === 99 &&
          this.todoType === 'dealWith' &&
          this.documentNodeIndex === this.stepIndex &&
          this.recipientStatus === 0
    }
  },
  methods: {
    carbonCopy() {
      this.$emit('carbonCopy')
    }
  }
}
</script>
